.Profile {
    float: right;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.Profile:hover {
    cursor: pointer;
    background-color: #a70e39;
}

.ProfileText {
    color: #EEEEEE;
    margin-left: 10px;
}
