.LoginBox {
    min-height: 300px;
}

.LoginBox :global .ant-card-body {
    padding: 0;
}

.LoginForm {
    padding: 20px;
}

.LoginInfo {
    padding: 20px;
    min-height: 300px;
    background-color: #333;
}

.LoginInfo :global .ant-card-body {
    height: 100%;
}

.LoginFormik {
    margin-top: 20px;
}

.Logo {
    text-align: center;
    width: 70%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 20px auto 30px;
    background-color: #FFF;
}

.Logo img {
    width: 80%;
}

.ErrorMessage {
    margin-top: 10px;
}

.ForgotPassword {
    cursor: pointer;
    border-bottom: 1px dotted;
    text-align: right;
}
